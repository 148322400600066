.imgbox {
    display: grid;
    height: 100%;
    /* margin: 10px; */
}

.centerFit {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}