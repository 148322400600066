.app {
    text-align: center;
    margin: 0;
}


.menu {
    background: #FFFFFF;
    display: block;
    max-width: 785px;
    margin: auto;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        font-weight: 800;
        font-size: 14px;
        line-height: 17px;
        // letter-spacing: 0.175em;
    }

    li {
        float: left;
    }

    li button {
        display: block;
        color: black;
        padding: 10px 18px 8px 18px;
        border-radius: 4px 4px 0px 0px;
        border: none;
        font: inherit;
        cursor: pointer;
    }

    li a {
        display: block;
        color: black;
        text-decoration: none;
        padding: 10px 18px 8px 18px;
        border-radius: 4px 4px 0px 0px;
    }

    li button:hover:not(.active) {
        background: #f0eee8ff;
    }

    li a:hover:not(.active) {
        background: #f0eee8ff;
    }

    .active {
        background: #f0eee8ff;
    }

    select {
        // A reset of styles, including removing the default dropdown arrow
        appearance: none;
        // Additional resets for further consistency
        background-color: transparent;
        border: none;
        padding: 0 1em 0 0;
        margin: 0;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        grid-area: select;
      }

    .select {
        // width: 100%;
        // min-width: 15ch;
        // max-width: 30ch;
        border: none;
        border-radius: 4px 4px 0px 0px;
        padding: 0.25em 0.5em;
        font-size: 1.25rem;
        cursor: pointer;
        line-height: 1.1;
        background-color: #fff;
        background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
        display: grid;
        grid-template-areas: "select";
        align-items: center;
        position: relative;

        &:after {
            content: "";
            width: 0.8em;
            height: 0.5em;
            background-color: #777;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            grid-area: select;
            justify-self: end;
        }
      }
}





